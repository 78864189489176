<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Jazyk</h3>
                    <h2 class="" v-if="permission.title">{{permission.title}}</h2>
                    <h2 class="" v-else>Nový jazyk</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Nastavenia</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <a-input alternative=""
                                                        label="Názov"
                                                        input-classes="form-control-alternative"
                                                        v-model="permission.title"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <a-input alternative=""
                                                        label="Skratka"
                                                        input-classes="form-control-alternative"
                                                        v-model="permission.short"
                                                        id="short"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <a-checkbox class="mb-3" v-model="permission.def"  :checked="beool(permission.def)" >
                                                Primárny jazyk
                                            </a-checkbox>
                                        </div>
                                    </div>
                                </div>


                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'

    Vue.use(FormItem);


    export default {
        name: 'language',
        props: ['id'],

        data() {
            return {
                head: authHeader(),
                send: API_URL,
                colorsto: JSON.parse(localStorage.colors),
                permissions: ["editácia používateľov"],
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
                permissionId: {
                    uid: this.id
                },
                permission: {
                    title: '',
                    slug: '',
                    def: false
                },
            }
        },
        methods: {
            handleSubmit() {
                let $permission_data = this.permission;
                dataService.axiosPost(this.$options.name, $permission_data).then(results => {

                    if(results.data.message)
                    {this.permission = []; }


                });
            },
            beool(v) {
                return v === "true" || v === "1" ? true : false;
            },
        },
        mounted() {
            if (!isNaN(this.id)) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.permission = results;
                    this.permission.def = this.beool(this.permission.def);
                });
            }


            let name = document.getElementById('short');
            name.addEventListener('keyup', () => {
                if (name.value.length > 3) {
                    name.value = name.value.slice(0, 3);
                }
            })
            name.addEventListener('focusout', () => {
                if (name.value.length > 3) {
                    name.value = name.value.slice(0, 3);
                }
            })
            name.addEventListener('focus', () => {
                if (name.value.length > 3) {
                    name.value = name.value.slice(0, 3);
                }
            })

        }
    };
</script>
<style>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

</style>
